<template>
    <div class="d-flex flex-column fill-height">
        <v-col>
            <v-row no-gutters class="align-center">
                <v-col class="col-9-5 d-flex flex-column">
                    <span>{{ $t('message.date') + ": " + Ipa__date }}</span>
                </v-col>
                <v-col class="col-2-5 text-right">
                    <img
                        :src="appLogo"
                        class="mb-3 mr-3"
                        width="130px"
                    />
                </v-col>
            </v-row>
            <v-row no-gutters>
                <v-col>{{ $t('message.dearCustomer') + "," }}</v-col>
            </v-row>
            <v-row no-gutters class="pt-7 pb-5">
                <v-col cols="12" style="font-size: 1.85em; font-weight: bolder">{{ $t('message.importPermitForContract') + " " + Contract__title }}</v-col>
            </v-row>
            <v-row no-gutters class="pt-3">
                <v-col cols="12">{{ ipaConstants.IpaNote1 }}</v-col>
            </v-row>
            <v-row no-gutters class="pt-3">
                <v-col cols="12" class="font-weight-bold">{{ $t('message.consignorDetails') }}</v-col>
            </v-row>
            <v-row no-gutters>
                <v-col cols="5">{{ $t('message.rocRobNo') }}</v-col>
                <v-col cols="7">{{ $t('message.na') }}</v-col>
            </v-row>
            <v-row no-gutters>
                <v-col cols="5">{{ $t('message.companyName') }}</v-col>
                <v-col cols="7">{{ Supplier__title }}</v-col>
            </v-row>
            <v-row no-gutters>
                <v-col cols="5">{{ $t('message.companyAddress') }}</v-col>
                <v-col cols="7" class="d-flex flex-column" v-if="Ipa__use_alt_address != 1">
                    <span>{{ Supplier__address1 }}</span>
                    <span>{{ Supplier__address2 }}</span>
                    <span>{{ Supplier__address3 }}</span>
                    <span>{{ Supplier__city + ' ' + Supplier__postcode}}</span>
                    <span>{{ Supplier__state }}</span>
                    <span>{{ Supplier__country }}</span>
                </v-col>
                <v-col cols="7" class="d-flex flex-column" v-if="Ipa__use_alt_address == 1">
                    <span v-html="Ipa__alt_address ? nl2br(Ipa__alt_address) : ''"></span>
                </v-col>
            </v-row>
            <v-row no-gutters class="pt-3">
                <v-col cols="12" class="font-weight-bold">{{ $t('message.applicationDetails') }}</v-col>
            </v-row>
            <v-row no-gutters>
                <v-col cols="5">{{ $t('message.modeOfTransport') }}</v-col>
                <v-col cols="7">{{ ipaConstants.IpaTransportMode }}</v-col>
            </v-row>
            <v-row no-gutters>
                <v-col cols="5">{{ $t('message.voyageNumber') }}</v-col>
                <v-col cols="7">{{ Contract__shipmentstatus_text }}</v-col>
            </v-row>
            <v-row no-gutters>
                <v-col cols="5">{{ $t('message.purpose') }}</v-col>
                <v-col cols="7">{{ ipaConstants.IpaPurpose }}</v-col>
            </v-row>
            <v-row no-gutters>
                <v-col cols="5">{{ $t('message.traderReferenceNumber') }}</v-col>
                <v-col cols="7">{{ Contract__title }}</v-col>
            </v-row>
            <v-row no-gutters>
                <v-col cols="5">{{ $t('message.countryOfOrigin') }}</v-col>
                <v-col cols="7">{{ Origin__country }}</v-col>
            </v-row>
            <v-row no-gutters>
                <v-col cols="5">{{ $t('message.placeOfOrigin') }}</v-col>
                <v-col cols="7">{{ Loadingport__title }}</v-col>
            </v-row>
            <v-row no-gutters>
                <v-col cols="5">{{ $t('message.consignedFrom') }}</v-col>
                <v-col cols="7">{{ Consignment__country }}</v-col>
            </v-row>
            <v-row no-gutters>
                <v-col cols="5">{{ $t('message.eta') }}</v-col>
                <v-col cols="7">{{ Contract__etadate }}</v-col>
            </v-row>
            <v-row no-gutters>
                <v-col cols="5">{{ $t('message.etd') }}</v-col>
                <v-col cols="7">{{ Contract__etddate }}</v-col>
            </v-row>
            <v-row no-gutters>
                <v-col cols="5">{{ $t('message.customsStationCode') }}</v-col>
                <v-col cols="7">{{ ipaConstants.IpaCustomsStationCode }}</v-col>
            </v-row>
            <v-row no-gutters>
                <v-col cols="5">{{ $t('message.entryPoint') }}</v-col>
                <v-col cols="7">{{ Entryport__title }}</v-col>
            </v-row>
            <v-row no-gutters>
                <v-col cols="5">{{ $t('message.applicationRemarks') }}</v-col>
                <v-col cols="7">{{ Entryport__title }}</v-col>
            </v-row>
            <v-row no-gutters class="pt-3">
                <v-col cols="12" class="font-weight-bold">{{ $t('message.additionalDetails') }}</v-col>
            </v-row>
            <v-row no-gutters>
                <v-col cols="5">{{ $t('message.category') }}</v-col>
                <v-col cols="7">{{ ipaConstants.IpaCategory }}</v-col>
            </v-row>
            <v-row no-gutters>
                <v-col cols="5">{{ $t('message.gmo') }}</v-col>
                <v-col cols="7">{{ ipaConstants.IpaGmo }}</v-col>
            </v-row>
            <v-row no-gutters>
                <v-col cols="5">{{ $t('message.methodOfDelivery') }}</v-col>
                <v-col cols="7">{{ ipaConstants.IpaDeliveryMethod }}</v-col>
            </v-row>
            <v-row no-gutters>
                <v-col cols="5">{{ $t('message.packageType') }}</v-col>
                <v-col cols="7">{{ ipaConstants.IpaPackType }}</v-col>
            </v-row>
            <v-row no-gutters class="pt-3">
                <v-col cols="12" class="font-weight-bold">{{ $t('message.itemDetails') }}</v-col>
            </v-row>
            <v-row no-gutters>
                <v-col cols="5">{{ $t('message.commonName') }}</v-col>
                <v-col cols="7">{{ Species__title }}</v-col>
            </v-row>
            <v-row no-gutters>
                <v-col cols="5">{{ $t('message.scientificName') }}</v-col>
                <v-col cols="7">{{ Species__latin_name }}</v-col>
            </v-row>
            <v-row no-gutters>
                <v-col cols="5">{{ $t('message.descriptionForm') }}</v-col>
                <v-col cols="7">{{ ipaConstants.IpaDescriptionForm }}</v-col>
            </v-row>
            <v-row no-gutters>
                <v-col cols="5">{{ $t('message.unitDescription') }}</v-col>
                <v-col cols="7">{{ ipaConstants.IpaUnitOfDescription }}</v-col>
            </v-row>
            <v-row no-gutters>
                <v-col cols="5">{{ $t('message.citesNo') }}</v-col>
                <v-col cols="7">{{ $t('message.na') }}</v-col>
            </v-row>
            <v-row no-gutters>
                <v-col cols="5">{{ $t('message.plantQuarantineQuantity') }}</v-col>
                <v-col cols="7">{{ Ipa__quarantine_qty + " " + Ipa__uofm }}</v-col>
            </v-row>
            <v-row no-gutters>
                <v-col cols="5">{{ $t('message.tariffQty') }}</v-col>
                <v-col cols="7">{{ Ipa__tariff_qty + " " + Ipa__uofm }}</v-col>
            </v-row>
            <v-row no-gutters>
                <v-col cols="5">{{ $t('message.tariffCode') }}</v-col>
                <v-col cols="7">{{ Species__hscode }}</v-col>
            </v-row>
            <v-row no-gutters>
                <v-col cols="5">{{ $t('message.unitOfMeasurement') }}</v-col>
                <v-col cols="7">{{ ipaConstants.IpaUnitOfMeasurement }}</v-col>
            </v-row>
            <v-row no-gutters>
                <v-col cols="5">{{ $t('message.tariffDescription') }}</v-col>
                <v-col cols="7">{{ ipaConstants.IpaTariffDescription }}</v-col>
            </v-row>
            <v-row no-gutters class="pt-5">
                <v-col>{{ ipaConstants.IpaNote2 }}</v-col>
            </v-row>
            <v-row no-gutters class="pt-5">
                <v-col>{{ $t('message.thankYou') + "," }}</v-col>
            </v-row>
            <v-row no-gutters class="pt-5">
                <v-col class="d-flex flex-column">
                    <div class="font-weight-bold">APP Timber</div>
                    <div><span>Operations - </span><span class="font-italic">we are here to serve you!</span></div>
                    <div>HQ Malaysia</div>
                </v-col>
            </v-row>
        </v-col>
        <h1 id="loaded" v-if="loaded" style="margin-bottom: 0px !important;"></h1>
    </div>
</template>

<script>
    import {formatDate, numberFormat, nl2br} from "../../../helpers/helpers";
    import {api} from "../../../api";
    import {
        ipaConstants, uofm
    } from "../../../store/modules/appic/constants"
    import AppConfig from "Constants/AppConfig";

    export default {
        name: "PrintIpa",
        props: ['ipaId'],
        data() {
            return {
                appLogo: AppConfig.appSessionLogo,
                ipaConstants: ipaConstants,
                loaded: false,
                uofmQty: uofm.qty,
                Consignment__country: null,
                Contract__etadate: null,
                Contract__etddate: null,
                Contract__shipmentstatus_text: null,
                Contract__title: null,
                Entryport__title: null,
                Ipa__alt_address: null,
                Ipa__date: null,
                Ipa__ipasent_date: null,
                Ipa__measurement_id: 1,
                Ipa__quarantine_qty: null,
                Ipa__tariff_qty: null,
                Ipa__use_alt_address: 0,
                Origin__country: null,
                Species__title: null,
                Species__hscode: null,
                Species__hscode_veneer: null,
                Species__latin_name: null,
                Supplier__title: null,
                Supplier__address1: null,
                Supplier__address2: null,
                Supplier__address3: null,
                Supplier__city: null,
                Supplier__country: null,
                Supplier__country_id: null,
                Supplier__gstno: null,
                Supplier__postcode: null,
                Supplier__state: null,
            }
        },
        computed: {
            // Ipa__date (){
            //     let d = new Date()
            //     return [d.getFullYear(),(d.getMonth() + 1).toString().padStart(2,'0'),d.getDate()].join('-');
            // },
            Ipa__uofm () {
                return this.uofmQty.find((u) => u.Measurement.id == this.Ipa__measurement_id)?.Measurement?.title
            }
        },
        methods: {
            formatDate,
            formatThisNumber(value,format){
                return numberFormat(value,format)
            },
            loadIpaById(ipa_id) {
                let self = this
                return new Promise((resolve, reject) => {
                    api
                        .get("/print/ipas/" + ipa_id,{
                        })
                        .then(response => {
                            for(let key in response.data.data[0]){
                                self[key] = response.data.data[0][key]
                            }
                            resolve(ipa_id)
                        })
                        .catch(error => {
                            reject(error);
                        })
                        .finally(()=>{

                        })
                })
            },
            nl2br
        },
        created () {
            if(this.$route.matched.some(({name}) => name === 'print_ipa')){
                this.loadIpaById(this.ipaId)
                    .then(()=>{
                        this.loaded = true
                    })
            }
        }
    }
</script>

<style>
    @font-face {
        font-family: 'AppicArial';
        src: url('/static/fonts/Arial.woff2') format('woff2'),
        url('/static/fonts/Arial.woff2') format('woff'),
        url('/static/fonts/Arial.woff2') format('truetype');
    }
    .col-2-5 {
        flex: 0 0 20.83333333%;
        max-width: 20.83333333%;
    }
    .col-9-5 {
        flex: 0 0 79.16666667%;
        max-width: 79.16666667%;
    }
    .v-application{
        font-family: 'AppicArial' !important;
        line-height:15px !important;
        font-size:9pt !important;
        padding:2px !important;
    }
    body {
        width: 18.5cm !important;
        margin: 0 !important;
    }
</style>